* {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.choice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-drag: none;
  overflow: "hidden";
  vertical-align: bottom;
}

.choice-label {
  text-align: center;
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
  font-size: 20px;
  color: #fffeef;
  padding-top: 10px;
}

/* .content-container {
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: relative;
} */

/* .pagename {
  z-index: 1;
  padding-top: 120px;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 0;
  color: #fffeef;
  font-family: "Roboto Mono", monospace;
  text-align: center;
} */

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
